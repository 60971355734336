<template>
  <div>
    <h1 class="py-6">Műveletek</h1>

    <div class="row">
      <div class="col-xs-12">
        <h2>Kereső</h2>
      </div>
      <div class="col-xs-2">
        <comboboxV2
          id="type"
          label="Típus"
          name="type"
          v-model="search.type_id"
          :options="types"
        ></comboboxV2>
      </div>
      <div class="col-xs-1">
        <toggle
          id="inout"
          label="Végrehajtott"
          name="inout"
          v-model="search.inout"
        ></toggle>
      </div>
      <!--            <div class="col-xs-3">
              <button class="button with-icon" style="margin-top: 24px" type="button" v-on:click="searchClick"><i class="customicon customicon-search"></i>Keresés</button>
            </div>-->
    </div>

    <div v-if="operations.length > 0" class="table-with-title">
      <h2>Műveletek</h2>
      <table class="list">
        <thead>
          <tr>
            <th>Létrehozva</th>
            <th>Típus</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(operation, i) in operations" :key="opration.name + i">
            <td>{{ operation.created }}</td>
            <td>{{ operation.name }}</td>
            <td>
              <i
                v-on:click="showParamsPopup(operation)"
                style="font-size: 18px; cursor: pointer"
                class="customicon customicon-info-circle"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="operations.length == 0" class="normal-text">
      Nem található művelet.
    </div>
    <div v-if="params_popup.visible" class="popup-overlayer">
      <div style="min-width: 500px" class="popup">
        <a
          v-on:click="hideParamsPopup"
          href="javascript:void(0)"
          class="close-btn"
          ><i class="customicon customicon-close"></i
        ></a>
        <h2>Paraméterek</h2>
        <div class="text"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import JSONFormatter from "json-formatter-js";

export default {
  components: {},

  data: function () {
    return {
      search: {
        type_id: 0,
        page: 1,
        limit: 100,
        amount: 0,
        inout: {
          value: 1,
          selected: false,
        },
      },

      operations: [],
      types: [],
      params_popup: {
        msg: "",
        visible: false,
      },
    };
  },

  watch: {
    $route: "routerChange",
  },

  created: function () {
    this.routerChange();
  },

  mounted: function () {
    var _this = this;

    this.getTypes();
    this.getOperations();

    this.$root.interval = setInterval(function () {
      var search = JSON.parse(JSON.stringify(_this.search));
      search.page = 1;
      search.inout = _this.search.inout.selected ? 1 : 0;
      _this.getOperations();
    }, 1000);
  },

  methods: {
    hideParamsPopup: function () {
      this.params_popup.visible = false;
    },

    showParamsPopup: function (log) {
      this.params_popup.visible = true;

      Vue.nextTick(function () {
        $(".popup .text").html(
          new JSONFormatter(JSON.parse(log.params)).render()
        );
      });
    },

    routerChange: function () {
      if (Object.keys(this.$route.query).length != 0) {
        this.search = $.extend(
          this.search,
          JSON.parse(JSON.stringify(this.$route.query))
        );
      }

      this.search.inout = {
        value: 1,
        selected: this.search.inout == 1 ? true : false,
      };

      this.getOperations();
    },

    searchClick: function () {
      if (this.$root.interval != undefined) clearInterval(this.$root.interval);

      var search = JSON.parse(JSON.stringify(this.search));
      search.page = 1;
      search.inout = this.search.inout.selected ? 1 : 0;
      this.$router.push({ path: "/operations", query: search });
    },

    getTypes: function () {
      var _this = this;

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax("operation_types/" + _this.$store.state.token, {
          success: function (response) {
            var tmp = [
              {
                label: "- válassz -",
                value: 0,
              },
            ];
            response.types.map(function (response) {
              tmp.push({
                label: response.name,
                value: response.id,
              });
            });
            _this.types = tmp;
          },
        });
      });
    },

    getOperations: function () {
      var _this = this;

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax("operations/" + _this.$store.state.token, {
          method: "post",
          data: _this.search,
          success: function (response) {
            _this.operations = response.operations;
            _this.search.amount = response.amount;
          },
        });
      });
    },
  },
};
</script>
